exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-diensten-js": () => import("./../../../src/pages/diensten.js" /* webpackChunkName: "component---src-pages-diensten-js" */),
  "component---src-pages-diensten-myopiemanagement-js": () => import("./../../../src/pages/diensten/myopiemanagement.js" /* webpackChunkName: "component---src-pages-diensten-myopiemanagement-js" */),
  "component---src-pages-eyewear-js": () => import("./../../../src/pages/eyewear.js" /* webpackChunkName: "component---src-pages-eyewear-js" */),
  "component---src-pages-glazen-js": () => import("./../../../src/pages/glazen.js" /* webpackChunkName: "component---src-pages-glazen-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lenzen-js": () => import("./../../../src/pages/lenzen.js" /* webpackChunkName: "component---src-pages-lenzen-js" */),
  "component---src-pages-over-ons-js": () => import("./../../../src/pages/over-ons.js" /* webpackChunkName: "component---src-pages-over-ons-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-templates-eyewear-brand-js": () => import("./../../../src/templates/eyewear-brand.js" /* webpackChunkName: "component---src-templates-eyewear-brand-js" */),
  "component---src-templates-eyewear-collection-js": () => import("./../../../src/templates/eyewear-collection.js" /* webpackChunkName: "component---src-templates-eyewear-collection-js" */)
}

